@import "assets/font/stylesheet.css";
@import "styleguides.css";
@import "shared/shared.scss";

html,
body {
  margin: 0;
}

#root {
  min-height: 100vh;

  --menu-width: 250px;
  --app-content-header-height: 60px;
  --form-width: 800px;

  @media (max-width: 1250px) {
    --menu-width: 180px;
  }

  .app-layout {
    min-height: 100vh;

    .app-menu {
      background: var(--neutral-2, #fafafa);
      width: var(--menu-width, 250px);
      height: 100vh;
      position: fixed;
      top: 0;
      left: 0;

      .logo {
        height: 68px;
        margin-bottom: 12px;
        display: flex;
        align-items: center;
        justify-content: start;
        padding-left: 24px;
        text-decoration: none;

        img {
          width: 120px;
          height: 21px;
        }
      }

      .menu-item {
        padding: 8px 8px 8px 16px;
        height: 20px;
        display: flex;
        align-items: center;
        flex-direction: row;
        grid-gap: 10px;

        margin-left: 12px;
        margin-right: 12px;

        color: var(--neutral-13, #000);

        /* H5/regular */
        font-family: Noto Sans SC;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        /* 150% */
        text-decoration: none;

        &.active {
          background: var(--neutral-5, #d9d9d9);
          border-radius: 8px;
        }
      }

      .menu-item-icon {
        background: var(--hit-box, rgba(255, 255, 255, 0));
      }

      .menu-item-logout {
        position: fixed;
        bottom: 0;
      }
    }

    .app-content-header {
      position: fixed;
      top: 0;
      left: var(--menu-width, 250px);
      width: calc(100vw - var(--menu-width, 250px));
      box-sizing: border-box;

      display: flex;
      justify-content: space-between;
      align-items: center;

      background-color: #fff;

      padding: 12px 32px;
      z-index: 100;

      .ant-avatar {
        background-color: var(--pnk-color-3, #ffedd4);
        color: var(--pnk-color-6, #f09f54);
      }

      .app-content-title {
        display: flex;
        flex-direction: column;
        padding-left: 12px;

        span:nth-child(1) {
          color: var(--neutral-9, #434343);

          /* H4/medium */
          font-family: Noto Sans SC;
          font-size: 20px;
          font-style: normal;
          font-weight: 500;
          line-height: 28px;
          /* 140% */
        }

        span:nth-child(2) {
          color: var(--neutral-7, #8c8c8c);

          /* Footnote/description */
          font-family: Noto Sans SC;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
          /* 166.667% */
        }

        .app-content-title2 {
          /* H4/medium */
          font-family: Noto Sans SC;
          font-size: 20px;
          font-style: normal;
          font-weight: 500;
          line-height: 28px;
          /* 140% */

          a {
            text-decoration: none;

            color: var(--character-secondary-45, #434343);
          }

          .app_current_breadcrumb {
            color: var(--neutral-9, #434343);
          }
        }
      }

      .app-content-avatar {
        display: flex;
        align-items: center;
        justify-content: center;

        div {
          padding-left: 10px;
          padding-right: 30px;

          min-width: 170px;
          max-width: 350px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        button {
          display: flex;
          align-items: center;
        }
      }
    }

    .app-content {
      margin-left: calc(var(--menu-width, 250px) + 24px);
      margin-top: calc(var(--app-content-header-height, 80px) + 20px);
      padding: 20px;

      box-sizing: border-box;
      top: 0;
      left: 0;
      width: calc(100vw - var(--menu-width, 250px) - 48px - 16px);
      min-height: calc(100vh - var(--app-content-header-height, 80px) - 100px);

      background-color: #fff;

      &.bg_opacity {
        background-color: transparent;
      }
    }
  }

  .main_screen {
    .main_screen_items {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-gap: 16px;
    }

    .main_screen_item {
      display: flex;
      flex-direction: column;
      background-color: #fff;
      padding: 32px 24px;

      text-decoration: none;
      border: 1px solid var(--neutral-4, #f0f0f0);
      border-radius: 8px;
      transition: 0.3s border;

      &:hover {
        border: 1px solid var(--pnk-color-6, #f09f54);
      }

      .header {
        display: flex;
        justify-content: space-between;
        align-items: start;
      }

      .wrap-img {
        width: 56px;
        height: 56px;
        background-image: url("./../public/menu/bg_icon.svg");
        display: flex;
        align-items: center;
        justify-content: center;

        margin-bottom: 16px;

        img {
        }
      }

      .title {
        color: var(--neutral-13, #000);

        /* H5/medium */
        font-family: Noto Sans SC;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        /* 150% */
      }

      .desc {
        color: var(--neutral-7, #8c8c8c);

        /* Body/regular */
        font-family: Noto Sans SC;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
        /* 157.143% */
      }

      &.disabled {
        &:hover {
          border: 1px solid white;
        }

        .title,
        .desc {
          color: var(--neutral-6, #bfbfbf);
        }
      }
    }
  }

  .auth_screen {
    display: flex;
    align-content: center;
    justify-content: center;
    flex-direction: column;
    height: 100vh;
    flex-wrap: wrap;
    background: var(--neutral-3, #f5f5f5);

    .logo {
      width: 200px;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 48px;
    }

    .auth_form {
      width: 364px;

      .btn_submit {
        margin-top: 16px;
        width: 100%;
      }

      .btn_restore_pass {
        width: 100%;
      }
    }
  }

  .restore_screen {
    display: flex;
    align-content: center;
    justify-content: center;
    flex-direction: column;
    height: 100vh;
    flex-wrap: wrap;
    background: var(--neutral-3, #f5f5f5);

    .logo {
      width: 200px;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 48px;
    }

    .header {
      color: var(--character-primary-85, rgba(0, 0, 0, 0.85));
      text-align: center;
      /* H4/medium */
      font-family: Noto Sans SC;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: 28px;
      /* 140% */
    }

    .text {
      width: 364px;

      text-align: center;
      /* Body/regular */
      font-family: Noto Sans SC;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
      /* 157.143% */

      margin: 8px 0 32px;
    }

    .btn_submit {
      margin-top: 16px;
      width: 100%;
    }

    .btn_restore_pass {
      width: 100%;
    }
  }

  .categories_list {
    .sort_categories {
      .ant-table-row-expand-icon {
        position: relative;
        left: 90px;
        z-index: 100;
      }

      .icon_sort {
        position: relative;
        left: -0px;
      }
    }

    .ant-table-row-level-1 {
      .column_title {
        padding-left: 60px;
      }
    }
  }

  .category_view,
  .category_edit {
    .CategoryZonesListTitle {
      color: var(--character-title-85, rgba(0, 0, 0, 0.85));
      /* H5/medium */
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      /* 150% */
    }
  }

  .zone_view,
  .zone_edit {
    .ZoneListTitle {
      color: var(--character-title-85, rgba(0, 0, 0, 0.85));
      /* H5/medium */
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      /* 150% */
    }
  }

  .role_list {
  }

  .role_form {
    .RoleFormTable {
      .ant-table-row-expand-icon {
        position: absolute;
        left: 10px;
        z-index: 100;
      }
    }
  }

  .user_view {
    .DataInfo {
      .DataInfoRow1 {
        display: grid;
        grid-template-columns: 100px 1fr;
        grid-gap: 10px;
        line-height: 180%;

        .img {
          width: 100px;
          height: 100px;
          background-position: center center;
          background-size: cover;

          border-radius: 8px;
          border: 1px solid var(--neutral-5, #d9d9d9);
          margin-bottom: 10px;
        }
      }
    }

    .UserGroupList {
      margin-top: -42px;
    }
  }

  .user_edit {
    .FormUserLayout {
      display: grid;
      grid-template-columns: 120px 1fr;

      .img {
        width: 100px;
        height: 100px;
        cursor: pointer;
        background-position: center center;
        background-size: cover;

        border-radius: 8px;
        border: 1px solid var(--neutral-5, #d9d9d9);
      }

      input[type="file"] {
        visibility: hidden;
      }
    }
  }

  .wrap-content {
    width: var(--form-width);
    max-width: 100%;
  }
}

.historyUsersFilter {
  padding-top: 10px;
  td {
    padding: 8px !important;
  }
}

.history_list {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  min-height: 85vh;

  table {
    // min-height: 80vh;
  }

  table td {
    vertical-align: top;
  }

  .history_datetime {
    width: 90px;
    min-width: 90px;

    color: var(--character-primary-85, rgba(0, 0, 0, 0.85));
    text-align: center;
    /* Footnote/description */
    font-family: Noto Sans SC;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    /* 166.667% */
    vertical-align: top;
  }

  .HistoryItemHeader {
    display: flex;
    align-items: center;

    .user_avatar {
      display: inline-block;
      width: 20px;
      height: 20px;
      border-radius: 20px;
      background-position: center center;
      background-size: cover;
      margin-right: 5px;
      margin-bottom: 5px;
    }

    .user_action {
      color: var(--character-title-85, rgba(0, 0, 0, 0.85));
      /* Footnote/description */
      font-family: Noto Sans SC;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      /* 166.667% */
    }

    .object_action {
      color: var(--sunset-orange-6, #fa8c16);
      font-family: Noto Sans SC;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      /* 166.667% */

      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      &.deleted {
        color: var(--character-danger, #ff4d4f);
        background: var(--dust-red-1, #fff1f0);
      }

      &.created {
        color: var(--character-danger, #ff4d4f);
        background: var(--dust-red-1, #fff1f0);
      }

      &.title {
        color: var(--character-secondary-45, rgba(0, 0, 0, 0.45));
      }
    }
  }

  .object_action {
    font-size: 12px;

    &.deleted {
      color: var(--character-danger, #ff4d4f);
      background: var(--dust-red-1, #fff1f0);
      line-height: 20px;

      a {
        color: var(--character-danger, #ff4d4f) !important;
        background: var(--dust-red-1, #fff1f0);
      }
    }
  }

  .history_key {
    color: var(--character-secondary-45, rgba(0, 0, 0, 0.45));
    font-family: Noto Sans SC;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    /* 133.333% */
  }

  .history_select {
    background: var(--sunset-orange-2, #ffe7ba);
    color: var(--character-primary-85, rgba(0, 0, 0, 0.85));
    font-family: Noto Sans SC;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    /* 133.333% */
    padding-left: 5px;
    padding-right: 5px;
    display: inline-block;
  }

  .history_old {
    padding-left: 10px;
    color: var(--character-secondary-45, rgba(0, 0, 0, 0.45));
    /* Footnote/description */
    font-family: Noto Sans SC;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 166.667% */
  }

  .history_new {
    color: var(--character-primary-85, rgba(0, 0, 0, 0.85));
    text-align: center;
    /* Footnote/description */
    font-family: Noto Sans SC;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 166.667% */
  }

  .AvatarPreview {
    margin-top: 5px;
    margin-bottom: 2px;
    width: 100px;
    height: 100px;
    background-position: center center;
    background-size: cover;
    display: inline-block;
  }

  .history_record {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    grid-gap: 10px;
    margin-bottom: 5px;
    max-width: 420px;

    & > :nth-child(1) {
      // width: 100px;
      // text-align: right;
    }
    & > :nth-child(2) {
      // width: 150px;
      text-align: left;
      // white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 100%;
    }
    & > :nth-child(3) {
      // width: 150px;
      text-align: left;
      // white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 100%;
    }
  }
}

.role_view {
  .ldap_desc {
    color: var(--character-secondary-45, rgba(0, 0, 0, 0.45));

    /* Body/regular */
    font-family: Noto Sans SC;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; /* 157.143% */

    position: relative;
    top: -10px;
  }
}

.RoleFormTable {
  width: 100%;
  max-width: 1200px;
}

.ant-form-item {
  margin-bottom: 16px;
}

.ant-table-wrapper
  .ant-table-tbody
  .ant-table-row.ant-table-row-selected
  > .ant-table-cell {
  color: var(--sunset-orange-6, #fa8c16);
}

.ant-btn.ant-btn-link,
.ant-btn-link:not(:disabled):not(.ant-btn-disabled):hover {
  color: var(--sunset-orange-6, #fa8c16);
}

td.column_title {
  color: var(--sunset-orange-6);
}

.ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-ink-bar {
  bottom: 45px;
}

.ant-tabs-top > .ant-tabs-nav::before,
.ant-tabs-bottom > .ant-tabs-nav::before,
.ant-tabs-top > div > .ant-tabs-nav::before,
.ant-tabs-bottom > div > .ant-tabs-nav::before {
  position: absolute;
  margin-bottom: 45px;
}

.ant-tabs {
  margin-top: 10px;
  margin-bottom: -10px;
}

.form_row_inline {
  display: grid;
  grid-gap: 10px;

  .ant-form-item-row {
    display: flex;
    flex-direction: column;
  }

  .ant-form-item-label {
    text-align: left;
    color: var(--character-secondary-45, rgba(0, 0, 0, 0.45));

    :after {
      display: none !important;
    }
  }

  .ant-btn {
    margin-top: 30px;
  }
}

.ant-form {
  .ant-form-item-label {
    :after {
      opacity: 0;
    }

    label {
      color: var(--character-secondary-45, rgba(0, 0, 0, 0.45));

      /* Footnote/description */
      font-family: Noto Sans SC;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 166.667% */
    }
  }
}

a {
  color: var(--sunset-orange-6);
}

a:hover {
  color: var(--pnk-color-6);
}

hr {
  opacity: 0.1;
}

.disabled_text {
  color: var(
    --character-disabled-placeholder-25,
    rgba(0, 0, 0, 0.25)
  ) !important;
}

.ant-table-placeholder {
  z-index: 1 !important;
}

.btn_delete_action {
  display: flex;
  height: 20px;
  align-items: center;
}

.ant-pagination {
  text-align: right;
}

.ant-pagination-options {
  float: left;
}

.ldapLabel {
  background-color: var(--pnk-color-6);
  color: #fff;

  font-family: Noto Sans SC;
  font-size: 9px;
  font-style: normal;
  font-weight: 700;
  line-height: 10px;

  display: inline-block;
  padding: 1px 3px;
  border-radius: 3px;

  position: relative;
  top: -2px;
}

.modalSaveSettings {
  .ant-typography-disabled {
    color: var(--character-secondary-45, rgba(0, 0, 0, 0.45)) !important;

    /* Body/regular */
    font-family: "Noto Sans SC";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; /* 157.143% */

    margin-bottom: 2px;
    display: inline-block;
  }
}