.app-tag {
  border-radius: 2px;
  border: 1px solid var(--neutral-5, #D9D9D9);
  background: var(--neutral-2, #FAFAFA);

  color: var(--neutral-7, #8C8C8C);

  padding-left: 8px;
  padding-right: 8px;

  /* Footnote/description */
  font-family: Noto Sans SC;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 166.667% */

  &.disabled {
    color: var(--neutral-7, #8C8C8C);
  }
}